<template>
  <div class="business-lines-table">
    <div class="row">
      <div class="col-lg-6 py-3">
        <h3>All Business Lines</h3>
      </div>
    </div>
    <ul class="table-header table-row">
      <li class="name head-cell">Name</li>
      <li class="type head-cell">Type</li>
      <li class="description head-cell">Description</li>
      <li class="image head-cell">Image</li>
      <li class="vendors head-cell">Vendors</li>
      <li class="action head-cell">Action</li>
    </ul>

    <template v-if="networkError === null && !networkFetching">
      <ul
        class="table-row body-row"
        v-for="line in businessLines"
        :key="line.id"
      >
        <li class="name body-cell">{{ line.name }}</li>
        <li class="type body-cell">{{ line.type }}</li>
        <li class="description body-cell">
          {{ line.description }}
        </li>
        <li class="image body-cell">
          <StackedImages :images="line.images" type="product" />
        </li>
        <li class="vendors body-cell">{{ line.vendors }}</li>
        <li class="action body-cell">
          <div class="action-box">
            <span class="edit-btn">Edit</span>
            <span
              class="view-btn"
              @click="viewStores(line.id, line.name, line.vendors)"
              >View</span
            >
            <span class="dropdown-btn" id="drop-chevron">
              <b-dropdown
                size="lg"
                variant="link"
                right
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <i class="fa fa-chevron-down"></i>
                </template>
                <b-dropdown-item href="#">Archive</b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </li>
      </ul>
    </template>
    <template v-else-if="!networkFetching && networkError !== null">
      <div class="text-danger text-center display-4 p-4">API Error</div>
      <div class="text-danger text-center lead pb-4">
        Error while fetchting business lines. Please refresh to try again.
      </div>
      <div class="text-danger text-center lead pb-4">
        {{ networkError }}
      </div>
    </template>
    <template v-else-if="networkError === null && networkFetching">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle mx-4"></b-spinner>
        <strong>Fetching...</strong>
      </div>
    </template>
  </div>
</template>

<script>
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import { mapState, mapGetters } from "vuex";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
export default {
  components: {
    StackedImages,
  },
  mounted() {
    this.$store.dispatch(GET_ALL_BUSINESS_LINES);
  },
  methods: {
    ...mapGetters(["getAllBusinessLines"]),
    updateLines: function () {
      this.$store.dispatch(GET_ALL_BUSINESS_LINES);
    },
    viewStores: function (id, name, nVendors) {
      if (nVendors < 1) return;
      this.$emit("viewStoreByLine", { id, name });
    },
  },
  computed: {
    ...mapState({
      networkError: function (state) {
        return state.businessLines.businessLineError;
      },
      networkFetching: function (state) {
        return state.businessLines.businessLinesFetching;
      },
      businessLines: (state) => {
        let arr = [];
        state.businessLines.allBusinessLines.map((item) => {
          arr.push({
            id: item._id,
            name: item.name,
            type:
              item.type && item.type === "productBased"
                ? "Product Based"
                : "Vendor Based",
            description: item.description,
            images: item.image,
            vendors: item.vendors ? item.vendors.length : 0,
          });
        });
        return arr;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
$red: #dc1828;
.business-lines-table {
  margin-top: 15px;

  ul.table-row {
    margin: 0;
    list-style: none;
    display: flex;
    background: #fff;
    width: 100%;
    padding: 5px;
    position: relative;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      &.head-cell {
        padding: 10px;
        color: $red;
        font-weight: 600;
      }
      &.body-cell {
        text-align: center;
      }
      &.name {
        width: 15%;
        justify-content: flex-start;
        &.name {
          padding-left: 25px;
        }
      }
      &.type {
        width: 15%;
      }
      &.description {
        width: 20%;
      }
      &.image {
        width: 15%;
      }
      &.vendors {
        width: 15%;
      }
      &.action {
        width: 20%;
        .action-box {
          width: 100%;
          span {
            display: inline-flex;
            margin: 5px;
            cursor: pointer;
            padding: 7px 20px;
            border-radius: 15px;
          }
          .edit-btn {
            background: #59fb71;
          }
          .view-btn {
            background: #73c2ff;
          }
          .dropdown-btn {
            align-items: center;
            padding: 2.5px;
            border-radius: 10px;
            background: #fff;
            border: 1px solid #ccc;
            ::v-deep .btn-lg.dropdown-toggle {
              padding: 0;
              i {
                padding: 0;
              }
            }
            ::v-deep .dropdown-menu {
              top: 10px !important;
              background: #f8b040;
              padding: 0;
              color: #fff;
              li {
                padding: 0;
                .dropdown-item:hover {
                  background: #f8b040;
                  filter: brightness(0.85);
                  color: #fff;
                }
              }
            }
            /* .archive-btn {
              position: absolute;
              bottom: -45px;
            
              left: -75px;
              display: none;
              border-radius: 10px;
            } */
          }
        }
      }
    }
  }
  ul.table-header {
    box-shadow: 0px 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    z-index: 3;
  }
  ul.body-row {
    margin-bottom: 2px;
  }
}
</style>
