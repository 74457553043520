<template>
  <div>
    <b-modal
      id="new-business-line-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-business-line-modal"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-business-line-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Business Line</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="createNewBusinessLine" @reset="resetForm">
        <div class="input-row">
          <b-form-input
            required
            v-model="form.name"
            placeholder="Name"
          ></b-form-input>
        </div>
        <div class="input-row">
          <b-form-input
            required
            v-model="form.description"
            placeholder="Description"
          ></b-form-input>
        </div>
        <div class="input-row center">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="form.type"
            :options="basedOptions"
            buttons
            name="radios-btn-default"
          ></b-form-radio-group>
        </div>
        <div class="input-row">
          <div class="image-upload">
            <label for="image-input" class="image-upload-label">
              <span class="icon">
                <i class="fa fa-camera"></i>
                <span class="text">Upload Image</span>
              </span>
            </label>
            <input
              @change="uploadFile"
              type="file"
              name="imaged"
              id="image-input"
              class="mt-3"
              ref="imageRef"
            />
          </div>
        </div>
        <div class="input-row center">
          <button
            type="Submit"
            class="create-button"
            :class="processingRequest"
          >
            Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
            />
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

import JwtService from "@/core/services/jwt.service";
export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        type: null,
        image: "",
      },
      basedOptions: [
        { text: "Product Based", value: "productBased" },
        { text: "Vendor Based", value: "vendorBased" },
      ],
      hasError: false,
      processingError: "",
      processingRequest: "",
    };
  },
  methods: {
    showModal: function () {
      this.$bvModal.show("new-business-line-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("new-business-line-modal");
    },
    uploadFile: function () {
      this.form.image = URL.createObjectURL(this.$refs.imageRef.files[0]);
    },
    resetForm: function () {
      this.form.name = "";
      this.form.description = "";
      this.form.productType = null;
      this.form.image = [];
    },
    createNewBusinessLine: function (e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = tk;
      ApiService.post("/businessLine/addBusinessLine", this.form).then(
        (res) => {
          if (res.data.success === 0) {
            this.hasError = "d-flex";
            this.processingError = res.data.message;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
            return;
          }
          this.resetForm();
          this.processingRequest = "";
          this.$emit("updateLines");
          this.$bvModal.hide("new-business-line-modal");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-business-line-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
        &::placeholder {
          font-weight: 600;
        }
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }

      .image-upload {
        label {
          display: flex;
          align-items: center;
          span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 32px;
              color: #000;
            }
            padding: 10px 20px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
    }
  }
}
</style>
